<template>
  <div>
    <b-card-code title="Add Payouts" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- Vendors -->
            <b-col md="6">
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Vendor"
                    rules="required"
                  >
                    <b-form-group
                      label="Vendor"
                      label-for="vendor"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="vendor"
                        v-model="vendorSelect"
                        :state="vendorSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="vendorOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Note" label-for="Note">
                    <validation-provider
                      #default="{ errors }"
                      name="Note"
                      rules="required"
                    >
                      <b-form-input
                        id="Note"
                        v-model="data.note"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="options.length > 0" md="6">
              <b-form-group>
                <template #label>
                  <b>ordres: </b><br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="options"
                    aria-controls="options"
                    class=""
                    @change="toggleAll"
                  >
                    {{ allSelected ? "Un-select All" : "Select All" }}
                  </b-form-checkbox>
                </template>

                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="options"
                    v-model="data.orders"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="options"
                    class="ml-4 h1"
                    aria-label="Individual options"
                    stacked
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
              <infinite-loading
                @infinite="selectedVendorState"
              ></infinite-loading>
            </b-col>
            <b-col cols="12">
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
  components: {
    BCardCode,
  },

  mixins: [heightTransition],
  watch: {
    vendorSelect: function() {
      this.options = [];
      this.currentPage = 0;
      if (this.vendorSelect != null) {
        this.selectedVendor();
      }
    },
    "data.orders": function(newValue, oldValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.options.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },

  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
      vendorOptions: [],
      vendorSelect: null,
      errors_back: [],
      available_amount: 0,
      showDismissibleAlert: false,
      required,
      data: {
        vendor_id: "",
        type: "manual",
        note: "",
        orders: [],
      },
      options: [],
    };
  },
  created() {
    axios
      .get("vendors")
      .then((result) => {
        const data = result.data.data;
        for (let index in data) {
          this.vendorOptions.push({
            text: data[index].firstname + " " + data[index].lastname,
            value: data[index].id.toString(),
          });
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    toggleAll(checked) {
      if (checked) {
        for (let index in this.options) {
          this.data.orders.push(this.options[index].value);
        }
      } else {
        this.data.orders = [];
      }
      // this.data.orders = checked ? this.options.slice() : []
    },
    // submit Brand API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.vendor_id = this.vendorSelect.value;
          axios
            .post("payouts/create", this.data)
            .then((result) => {
            
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },

    selectedVendor() {
      this.currentPage += 1;
      axios
        .get(
          "vendors/" +
            this.vendorSelect.value +
            "/orders/settled/50/sortBy-id-desc?page=" +
            this.currentPage
        )
        .then((result) => {
         
          const data = result.data.data.data;
          if (data.length > 0) {
            for (let index in data) {
              this.options.push({
                text:
                  "Code: " +
                  data[index].code +
                  " " +
                  "- " +
                  " " +
                  "Amount: Worthy " +
                  data[index].payout_total +
                  " " +
                  "EGP" +
                  " " +
                  "| Date: " +
                  data[index].created_at,
                value: data[index].id,
              });
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    selectedVendorState($state) {
      this.currentPage += 1;
      axios
        .get(
          "vendors/" +
            this.vendorSelect.value +
            "/orders/settled/50/sortBy-id-desc?page=" +
            this.currentPage
        )
        .then((result) => {
         
          const data = result.data.data.data;
          if (data.length > 0) {
            for (let index in data) {
              this.options.push({
                text:
                  "Code: " +
                  data[index].code +
                  " " +
                  "- " +
                  " " +
                  "Amount: Worthy " +
                  data[index].payout_total +
                  " " +
                  "EGP" +
                  " " +
                  "| Date: " +
                  data[index].created_at,
                value: data[index].id,
              });
            }
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    // basic
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>

<style></style>
